import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoInstagram,
  IoIosChatbubbles,
  IoIosHelpBuoy,
  IoMdOpen,
} from "react-icons/io";

import { useModal } from "../components/useModal";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Button, Container, Wrapper, Row, Box } from "../components/util";
import { WebsiteContactForm } from "../components/forms";
import { Modal, CallToAction } from "../components/site";

const SupportCard = styled(Box)`
  border-radius: ${(props) => props.theme.styles.borderRadius};
  padding: 60px;
  text-align: center;
  box-shadow: ${(props) => props.theme.styles.shadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 30px;
  }
`;

const Contact = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  useEffect(
    function() {
      if (props.location.search.substr(1) === "sales") {
        setItemModalOpen(true);
      }
    },
    [props.location.search, setItemModalOpen]
  );

  return (
    <Layout>
      <Seo
        title="How Can We Help? | Contact Sales | PayHero"
        description="We're a proudly New Zealand owned business based in Wellington. Have any questions? Here's how to get in touch with our friendly support & sales team."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper className="-textCenter" stackGap={80} noPaddingBottom>
          <h1>Hello. How can we help?</h1>
          <Row stackGap={40}>
            <SupportCard className="secondary" stackGap={40} size={50}>
              <Box stackGap={10}>
                <IoIosChatbubbles css={{ fontSize: "3rem" }} />
                <Box stackGap={7}>
                  <h2>Sales</h2>
                  <p>
                    Want to know how PayHero can help your business? Make a time
                    for us to call you or send our sales team a message.
                  </p>
                </Box>
              </Box>
              <Box stackGap={30}>
                <Button
                  className="primary -lg -center gtm-cta"
                  onClick={toggleModal}
                  atag
                >
                  Book a Sales Call
                </Button>
                <Link
                  className="-center link-floating"
                  css={{ fontSize: "1rem!important" }}
                  to="/contact#message"
                >
                  Send Sales a message
                </Link>
              </Box>
            </SupportCard>
            <SupportCard stackGap={40} size={50}>
              <Box stackGap={10}>
                <IoIosHelpBuoy css={{ fontSize: "3rem" }} />
                <Box stackGap={7}>
                  <h2>Support</h2>
                  <p>
                    Need some help with PayHero? Get in touch with our friendly
                    support team or visit our support centre.
                  </p>
                </Box>
              </Box>
              <Box stackGap={30}>
                <Button
                  className="primary -lg -center"
                  to="https://support.payhero.co.nz/hc/en-us"
                  atag
                  externalLink
                >
                  Go to Support Centre <IoMdOpen css={{ top: "2px" }} />
                </Button>
                <a
                  className="-center link-floating"
                  href="mailto:support@payhero.co.nz"
                  css={{ fontSize: "1rem!important" }}
                >
                  support@payhero.co.nz
                </a>
              </Box>
            </SupportCard>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <div className="site-anchor" id="message" />
      <Container>
        <Wrapper stackGap={80}>
          <Row stackGap={60}>
            <Box size={50} stackGap={50}>
              <div>
                <h2>Send our sales team a message</h2>
                <p>
                  How can we help you today? You can also reach us by emailing{" "}
                  <a href="mailto:sales@payhero.co.nz">sales@payhero.co.nz</a>.
                </p>
              </div>
              <div>
                <WebsiteContactForm />
              </div>
            </Box>
            <Box size={50} stackGap={45}>
              <Box stackGap={15}>
                <div>
                  <h3 className="-fontDark">Become a Partner</h3>
                  <p>
                    Interested in partnering with us? We’d love to hear from
                    you! Email{" "}
                    <a href="mailto:partners@flexitime.works">
                      partners@flexitime.works
                    </a>{" "}
                    and we’ll get back to you with more information.
                  </p>
                </div>
                <a
                  className="-fontBold"
                  target="_blank"
                  href="https://partners.flexitime.works"
                  css={{ textDecoration: "none" }}
                >
                  Learn about the FlexiTime Partner Program{" "}
                  <IoMdOpen css={{ position: "relative", top: "2px" }} />
                </a>
              </Box>
              <hr />
              <Box stackGap={15}>
                <div>
                  <h3 className="-fontDark">Product Support</h3>
                  <p>
                    Having trouble getting set up or have a curly payroll
                    question you can’t find the answer to? Our Support Centre
                    has in depth articles about PayHero.
                  </p>
                </div>
                <a
                  className="-fontBold"
                  target="_blank"
                  href="https://support.payhero.co.nz/hc/en-us"
                  css={{ textDecoration: "none" }}
                >
                  Go to Support Centre{" "}
                  <IoMdOpen css={{ position: "relative", top: "2px" }} />
                </a>
              </Box>
              <hr />
              <Box>
                <h3 className="-fontDark">Accounts & Billing</h3>
                <p>
                  Our accounts team is ready to answer any of your enquiries.
                  Email{" "}
                  <a href="mailto:accounts@payhero.co.nz">
                    accounts@payhero.co.nz
                  </a>{" "}
                  and we’ll get back to you asap.
                </p>
              </Box>
              <hr />
              <Box>
                <Row stackGap={40}>
                  <Box size={60}>
                    <h3 className="-fontDark">Our Office</h3>
                    <p>We’re based in the best little capital in the world.</p>
                    <p>
                      <b>11 Chews Lane, Level 1</b>
                    </p>
                    <p>
                      <b>Willis Street</b>
                    </p>
                    <p>
                      <b>PO Box 10067</b>
                    </p>
                    <p>
                      <b>Wellington 6143</b>
                    </p>
                    <p>
                      <b>New Zealand</b>
                    </p>
                  </Box>
                  <Box size={40} stackGap={20}>
                    <div>
                      <h3 className="-fontDark">Socials</h3>
                      <p>Stay up to date with PayHero.</p>
                    </div>
                    <Row stackGap={15} justify="flex-start" noBreak>
                      <a
                        href="https://www.facebook.com/PayHeroHQ/"
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ fontSize: "2rem" }}
                        aria-label="Follow us on Facebook"
                      >
                        <IoLogoFacebook />
                      </a>
                      {/* <a
                        href="https://twitter.com/FlexiTimeTeam"
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ fontSize: "2rem" }}
                        aria-label="Follow us on Twitter"
                      >
                        <IoLogoTwitter />
                      </a> */}
                      <a
                        href="https://www.linkedin.com/showcase/payheronz/"
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ fontSize: "2rem" }}
                        aria-label="Follow us on LinkedIn"
                      >
                        <IoLogoLinkedin />
                      </a>
                      {/* <a
                        href="https://instagram.com/flexitimehq"
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ fontSize: "2rem" }}
                        aria-label="Follow us on Instagram"
                      >
                        <IoLogoInstagram />
                      </a> */}
                    </Row>
                  </Box>
                </Row>
              </Box>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction showSalesButton />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Contact;
